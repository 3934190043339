import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

function About() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "about" } }) {
        edges {
          node {
            featured_media {
              localFile {
                id
                childImageSharp {
                  fluid(maxWidth: 750, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            content
            id
            title
          }
        }
      }
    }
  `);
  const page = data.allWordpressPage.edges[0].node;
  return (
    <div>
      <section className="w-full text-lg max-w-6xl mx-auto px-4 md:px-8 mb-8">
        <div className="about flex flex-col lg:flex-row -mx-4">
          <div className="w-full lg:w-5/12 p-4">
            <Img fluid={page.featured_media.localFile.childImageSharp.fluid} />
          </div>
          <div className="w-full lg:w-7/12 p-4 self-center order-first lg:order-last">
            <h2 className="text-4xl lowercase border-b-4 border-black border-double font-bold mb-4">
              {page.title}
            </h2>
            <div dangerouslySetInnerHTML={{ __html: page.content }}></div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;

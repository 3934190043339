import React from "react";
import Layout from "../components/layout";
import About from "@components/about/about";
import SEO from "../components/seo";

function AboutPage() {
  return (
    <Layout>
      <SEO keywords={[`Soi 29`, `Thai`, `Restaurant`, `Malmö`]} title="About | SOI 29 - Thai street food &amp; bar" />

      <About />
    </Layout>
  );
}

export default AboutPage;
